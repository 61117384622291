import { AttachmentEntity } from './attachment'
import { EntityBase } from './entity'
import { UserEntity } from './user_portal'

export enum EnumCampaignType {
  Format = 'format',
  Tokyolife = 'tokyolife'
}
export interface CampaignEntity extends EntityBase {
  name: string
  type: EnumCampaignType
  hide_sensitive_data?: boolean
  guide: string
  complete: boolean
  description: string
  slug: string
  created_by: UserEntity
  product_files?: AttachmentEntity[]
  created_at: string
}

export const CampaignTypeLabel: Record<EnumCampaignType, string> = {
  [EnumCampaignType.Format]: 'Format',
  [EnumCampaignType.Tokyolife]: 'Tokyolife'
}
