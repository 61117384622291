import { Tooltip, Typography } from '@mui/material'
import { commentsPopup } from 'components'
import { GenderLabelType, ProductEntity, TableColumn } from 'models'
import React from 'react'
import { store } from 'store'
export const Table: TableColumn<ProductEntity>[] = [
  {
    header: {
      label: 'Danh mục'
    },
    cell: {
      getValue: (a: ProductEntity) => (a.categories && a.categories.length > 0 ? a.categories.map((item) => item.name).join(' / ') : ''),
      width: 300
    }
  },
  // {
  //   header: {
  //     label: 'Nhóm hàng'
  //   },
  //   cell: {
  //     getValue: (a: ProductEntity) => a.categories?.[1]?.name ?? '',
  //     width: 120
  //   }
  // },
  // {
  //   header: {
  //     label: 'Dòng hàng'
  //   },
  //   cell: {
  //     getValue: (a: ProductEntity) => a.categories?.[2]?.name ?? '',
  //     width: 120
  //   }
  // },
  // {
  //   header: {
  //     label: 'Kết cấu'
  //   },
  //   cell: {
  //     getValue: (a: ProductEntity) => a.categories?.[3]?.name ?? '',
  //     width: 120
  //   }
  // },
  {
    header: {
      label: 'Bộ sưu tập'
    },
    cell: {
      getValue: (a: ProductEntity) => (a.collections && a.collections.length > 0 ? a.collections.map((item) => item.name).join(', ') : ''),
      width: 100
    }
  },
  {
    header: {
      label: 'Giới tính'
    },
    cell: {
      getValue: (a: ProductEntity) => (a.gender ? GenderLabelType[a.gender] : ''),
      width: 80
    }
  },
  {
    header: {
      label: 'Mã hàng'
    },
    cell: {
      getValue: (a: ProductEntity) => a.sku,
      width: 100
    }
  },
  {
    header: {
      label: 'Mã TK'
    },
    cell: {
      getValue: (a: ProductEntity) => a.design_code
    }
  },
  {
    header: {
      label: 'Tên'
    },
    cell: {
      getValue: (a: ProductEntity) => a.name,
      width: 300,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Đơn vị'
    },
    cell: {
      getValue: (a: ProductEntity) => a.count_unit ?? '-',
      width: 80
    }
  },
  {
    header: {
      label: 'Giá bán'
    },
    cell: {
      getValue: (a: ProductEntity) => a.price?.toLocaleString('vi-VN') || '-',
      width: 80
    }
  },
  {
    header: {
      label: 'Đặc điểm nổi bật'
    },
    cell: {
      getValue: (a: ProductEntity) => a.highlights ?? '-',
      width: 300
    }
  },
  // TODO: Show all comments of product in all campaigns
  {
    header: {
      label: 'Đánh giá'
    },
    cell: {
      getElement: (a: ProductEntity) =>
        a.rating_count == null || a.rating_count == 0 ? (
          <Typography style={{ fontSize: '13px', color: '#000' }}>Chưa có</Typography>
        ) : (
          <Tooltip
            title={'Xem danh sách đánh giá'}
            onClick={() => {
              console.log('Click view comments', store.getState().campaign.campaign)
              commentsPopup.show({ product: a.id, all: true, campaign: store.getState().campaign?.campaign?.id })
            }}
          >
            <Typography
              style={{ fontSize: '13px', textDecorationLine: 'underline', color: '#2660E5', cursor: 'pointer' }}
            >{`${a.rating_count} lượt - ${(a.rating_sum / a.rating_count).toFixed(2)}đ`}</Typography>
          </Tooltip>
        )
    }
  }
]

export default Table
